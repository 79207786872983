@font-face {
  font-family: 'Sligoil-Micro';
  src: url('./assets/font/Sligoil-Micro.woff') format('woff');
}

body {
  font-family: 'Sligoil-Micro', sans-serif;
  margin: 16px;
  background-image: radial-gradient(#999 .5px, #fdfdfd 0.5px);
  background-size: 10px 10px;
}

.word-list {
  list-style-type: none;
  padding: 0;
}

.word-list li {
  margin: 5px 0;
}

.word-list a {
  font-weight: lighter;
  text-decoration: none;
  color: #0000ab;
}

.word-list a:hover {
  text-decoration: underline;
}
